import { CheminListeMenu } from "../routeur/Chemin";
import MenuBouton from "./icone/MenuBouton";
import style from "./Menu.module.scss";
import MenuItem from '../menu/MenuItem';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LangueListeMenu, LangueNom } from "../localisation/Langue";
import { useLocation } from "react-router-dom";
import { isIState } from "../navigation/TypeState";
import useChangeStateLanguage from "../navigation/change-state/useChangeStateLanguage";
import useChangeStatePath from "../navigation/change-state/useChangeStatePath";

export default function Menu() {
  const changeStateLanguage = useChangeStateLanguage();
  const changeStatePath = useChangeStatePath();
  const location = useLocation();
  const [menuOuvert, setMenuOuvert] = useState<Boolean>(false);
  const { t } = useTranslation();

  const ouvrirFermerMenu = () => { setMenuOuvert(! menuOuvert); }

  return (
    <div className={style["menu-container"]}>
      <MenuBouton menuOuvert={menuOuvert} ouvrirFermerMenu={ouvrirFermerMenu} />
      {isIState(location.state)?
        <>
          <nav className={[style["menu"], ...menuOuvert ? [] : [style["invisible"]]].join(" ")}>
            {
              LangueListeMenu.map(langue =>
                <MenuItem
                  callbackArgument={langue}
                  key={langue}
                  setMobileMenuVisible={setMenuOuvert}
                  texte={LangueNom(langue)}
                  onClick={changeStateLanguage}
                />
              )
            }

            <p className={style["menu-separation"]} />

            {
              CheminListeMenu.map(chemin =>
                <MenuItem
                  callbackArgument={chemin}
                  key={chemin}
                  setMobileMenuVisible={setMenuOuvert}
                  texte={t(chemin + ".titre")}
                  onClick={changeStatePath}
                />
              )
            }
          </nav>
        </>
      : <></>}
    </div>
  );
}
