export enum Langue {
  fr = "fr",
  en = "en",
};

export const LangueDéfaut = Langue.fr;

export const LangueListeMenu = [
  Langue.fr,
  Langue.en
];

export function LangueNom(langue: Langue) {
  switch(langue) {
    case Langue.fr:
      return "Français";
    case Langue.en:
      return "English";
    default:
      return "";
  }
}
