import IconeSvgLienType, { TypeLien } from "./IconeSvgLienType";

interface ILienTypéProps {
  titre: string,
  type: TypeLien,
  url: string
}

export default function LienTypé({titre, type, url}: ILienTypéProps) {
  return (
    <>
      <a href={url}>{titre}</a> <IconeSvgLienType type={type} />
    </>
  )
}
