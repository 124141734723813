import style from "./MenuItem.module.scss";

interface IMenuItemProps<T> {
  callbackArgument: T,
  setMobileMenuVisible: (a: Boolean) => void,
  texte: string,
  onClick: (a: T) => void,
};

export default function MenuItem<T>(props: IMenuItemProps<T>) {
  const { callbackArgument, setMobileMenuVisible, texte, } = props;

  const changeLanguage = () => {
    setMobileMenuVisible(false);
    props.onClick(callbackArgument);
  };

  return (
    <button className={style["menu-item"]} onClick={changeLanguage}>
      {texte}
    </button>
  );
};
