import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export default function Accueil() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if(location.pathname === "/") {
      i18n.changeLanguage("fr");
      navigate("/fr/Romans", {replace: true});
    }
  }, [i18n, location.pathname, navigate]);

  return (<></>);
};
