import Accueil from "../pages/accueil/Accueil";
//import Cadre from "../cadre/Cadre";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Grid from "../grid/Grid";
import PageInexistante from "../pages/page-inexistante/PageInexistante";
import { CheminListeMenu, CheminVersPage } from "./Chemin";
import { LangueListeMenu } from "../localisation/Langue";
import InitializeState from "../navigation/initialize-state/InitializeState";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Accueil />,
  },
  {
    path: "/",
    children: LangueListeMenu.map(langue => {
      const dictionnaire = require("../localisation/" + langue + ".json");

      return {
        path: langue,
        element: <Grid />,
        children: CheminListeMenu.map(chemin => {
          return {
              path: dictionnaire[chemin]["titre"],
              element:
                <>
                  <InitializeState language={langue} path={chemin} />
                  {CheminVersPage(chemin)}
                </>,
            };
          })
        };
      }
    )
  },
  {
    path: "*",
    element: <PageInexistante />
  },
]);

export default function Routeur() {
  return (
    <RouterProvider router={router} />
  );
};
