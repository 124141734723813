import { useEffect } from "react";
import useTitre from "../../navigation/useTitre";

const SITE_WEB_AUTEUR = "Julien Ferté";

export default function DocumentTitre() {
  const titre = useTitre();

  useEffect(() => {
    document.title = titre().titre || SITE_WEB_AUTEUR;
  }, [titre]);

  return (<></>);
}
