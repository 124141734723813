import { Langue } from "../localisation/Langue";
import { Chemin } from "../routeur/Chemin";

/* location.state devient {language: "...", path: "..."}
*/
export interface IState {
  language: Langue,
  path: Chemin,
};

export type NotFound = "NotFound";

/* location.state devient soit un chemin admissible ou NotFound.
*/
type TypeState = IState | NotFound;

export default TypeState;

/* Détermine si location.state a une langue et un chemin.
 * Voir doc https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
*/
export const isIState = (s: TypeState): s is IState => {
  return (s as IState)?.language !== undefined;
}

/* Test d'égalité en profondeur.
 * L'opérateur == ne compare pas les objets en profondeur, d'où cette fonction.
*/
export function areStatesEqual(l: TypeState, r: TypeState) {
  var result = false;

  if(l == null && r == null) {
    result = true;
  }

  if(l != null && r != null) {
    if(!isIState(l) && !isIState(r)) {
      result = true;
    }

    if(isIState(l) && isIState(r)) {
      result = (l.language === r.language) && (l.path === r.path);
    }
  }

  return result;
}
