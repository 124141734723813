import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import traductionEN from "./en.json";
import traductionFR from "./fr.json";

const resources = {
  en: {
    translation: traductionEN
  },
  fr: {
    translation: traductionFR
  }
};

i18n
  .use(LanguageDetector) // Besoin pour mettre à jour {t, i18n} quand l'utilisateur va de la tab vide à
                         // la page d'accueil en parcourant l'historique de l'explorateur web.
  .use(initReactI18next) // passes i18next down to react-i18next
  .init({
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
    },
    
    resources,
 
    keySeparator: ".",  // to support nested translations
 
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  }
);

export default i18n;