import { useLocation } from "react-router-dom";
import useChangeState from "./useChangeState";
import { useCallback } from "react";
import TypeState, { isIState } from "../TypeState";
import { Chemin } from "../../routeur/Chemin";

export default function useChangeStatePath() {
  const location = useLocation();
  const changeState = useChangeState();

  return useCallback((path: Chemin) => {
    const state = location.state as TypeState;

    if(isIState(state) && state.path !== path) {
      changeState({language: state.language, path});
    }
  }, [location.state, changeState]);
}
