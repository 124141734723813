import style from "./RomanLigne.module.scss";

function faitURLPourPDF(titre : string) {
  return process.env.PUBLIC_URL + "/romans/" + titre + ".pdf";
}

interface IRomanLigneProps {
  titre: string
}

export default function RomanLigne({titre}: IRomanLigneProps) {
  return (
    <div className={style["ligne"]} key={titre}>
      <a href={faitURLPourPDF(titre)}>{titre}</a>
    </div>
  );
}
