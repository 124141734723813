import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TypeState, { areStatesEqual, isIState } from "../TypeState";
import { useTranslation } from "react-i18next";

export default function useInitializeState() {
  const { i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return useCallback((state: TypeState) => {
    if(isIState(state) && i18n.language !== state.language) {
      i18n.changeLanguage(state.language);
    }

    if(! areStatesEqual(location.state, state)) {
      navigate(location.pathname, {replace: true, state});
    }
  }, [i18n, location.pathname, location.state, navigate]);
}
