import PublicationLigne from "./PublicationLigne";
import listeDePublications from "./publications.json";
import style from "./Publications.module.scss";

export default function Publications() {
  return (
    <main className={style["contenu"]}>
      {listeDePublications.map((o, i) => PublicationLigne(o))}
    </main>
  );
};
