import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import useChangeState from "./useChangeState";
import TypeState, { isIState } from "../TypeState";
import { Langue } from "../../localisation/Langue";

export default function useChangeStateLanguage() {
  const location = useLocation();
  const changeState = useChangeState();

  return useCallback((language: Langue) => {
    const state = location.state as TypeState;

    if(isIState(state) && state.language !== language) {
      changeState({language, path: state.path});
    }
  }, [location.state, changeState]);
}
