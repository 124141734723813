import style from "./APropos.module.scss";
import { useTranslation } from "react-i18next";

const URL_PROFIL_LINKEDIN = "https://www.linkedin.com/in/julien-ferté-4029995a/";
const URL_PROFIL_GITHUB = "https://github.com/lejeuneretif";

export default function APropos() {
  const { t } = useTranslation();

  return (
    <main className={style["contenu"]}>
      <p>
        {t("a-propos.sur")} <a href={URL_PROFIL_LINKEDIN}>LinkedIn</a>
      </p>
      <p>
        {t("a-propos.sur")} <a href={URL_PROFIL_GITHUB}>GitHub</a>
      </p>
    </main>
  );
};
