import { ReactComponent as IconeSvgLienHTML } from "./icones/icone-lien-html.svg";
import { ReactComponent as IconeSvgLienPDF } from "./icones/icone-lien-pdf.svg";
import { ReactComponent as IconeSvgLienPS } from "./icones/icone-lien-ps.svg";

export enum TypeLien {
  HTML = "HTML",
  PDF = "PDF",
  PS = "PS",
}

interface IType {
  type: TypeLien,
}

export default function IconeSvgLienType({type}: IType) {
  switch(type) {
    case TypeLien.HTML:
      return <IconeSvgLienHTML />;
    case TypeLien.PDF:
      return <IconeSvgLienPDF />;
    case TypeLien.PS:
      return <IconeSvgLienPS />;
    default:
      return <></>;
  }
}
