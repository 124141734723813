import style from "./Roman.module.scss";
import RomanLigne from "./RomanLigne";
import listeDeRomans from "./romans.json";

export default function Romans() {
  return (
    <main className={style["contenu"]}>
      {listeDeRomans.map((o, i) => RomanLigne(o))}
    </main>
  );
}
