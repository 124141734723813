import { useEffect } from "react";

export default function PageInexistante() {
  useEffect(() => {
    document.title = "Page inexistante";
  });

  return (
    <main className="contenu">
      <h1>
        Page inexistante.
      </h1>

      <p>
        <a href="/">Retour à l'accueil.</a>
      </p>
    </main>
  );
}
