import "./App.module.scss";
import "./localisation/i18n";
import Routeur from "./routeur/Routeur";
import { useEffect } from "react";
import useIsMobile from "./mobile/useIsMobile";

export default function App() {
  const isMobile = useIsMobile();

  useEffect(
    () => {
      document.body.classList.remove(isMobile? "is-desktop" : "is-mobile");
      document.body.classList.add(isMobile? "is-mobile" : "is-desktop");
    },
    [ isMobile ]
  );

  return ( <Routeur /> );
};
