import APropos from "../pages/a-propos/APropos";
import Romans from "../pages/romans/Romans";
import Publications from "../pages/publications/Publications";

export enum Chemin {
  APropos = "a-propos",
  Publications = "publications",
  Romans = "romans",
};

export const CheminListeMenu = [
  Chemin.Romans,
  Chemin.Publications,
  Chemin.APropos
];

export function CheminVersPage(chemin: Chemin) {
  switch(chemin) {
    case Chemin.APropos:
      return (<APropos />);
    case Chemin.Publications:
      return (<Publications />);
    case Chemin.Romans:
      return (<Romans />);
    default:
      return (<></>);
  }
}
