import { TypeLien } from "./IconeSvgLienType";
import LienTypé from "./LienTypé";
import style from "./PublicationLigne.module.scss";

interface IPublicationLigneProps {
  titre: string,
  lien: {
    url: string,
    type: string,
  },
  auteur: string,
  journal: string,
}

export default function PublicationLigne({titre, lien, auteur, journal}: IPublicationLigneProps) {
  return (
    <div className={style["ligne"]} key={titre}>
      <LienTypé titre={titre} type={lien.type as TypeLien} url={lien.url} />
      <p>{auteur}</p>
      <p>{journal}</p>
    </div>
  );
};
