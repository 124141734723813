import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { isIState } from "./TypeState";
import { useTranslation } from "react-i18next";

export interface ITitreOptionel {
  titre?: string,
}

export default function useTitre() {
  const location = useLocation();
  const { t } = useTranslation();

  return useCallback(() => {
    return {
      ...(isIState(location.state) && {titre: t(location.state.path + ".titre")}),
    } as ITitreOptionel;
  }, [location.state, t]);
}
