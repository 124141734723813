import style from "./MenuBouton.module.scss";
import { ReactComponent as IconeMenuSvg } from "./icone-menu.svg";

interface IIconeMenuProps {
  menuOuvert: Boolean,
  ouvrirFermerMenu: () => void,
}

export default function MenuBouton( props:IIconeMenuProps ) {
  return (
    <button
      className={
        [style["menu-button"], props.menuOuvert? style["menu-ouvert"] : style["menu-ferme"]].join(" ")
      }
      onClick={props.ouvrirFermerMenu}
    >
      <IconeMenuSvg />
    </button>
  );
}
