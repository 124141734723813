import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TypeState, { areStatesEqual, isIState } from "../TypeState";
import { useTranslation } from "react-i18next";

export default function useChangeState() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return useCallback((state: TypeState) => {
    const currentState = location.state as TypeState;

    if(isIState(state) && i18n.language !== state.language) {
      i18n.changeLanguage(state.language);
    }

    if(! areStatesEqual(currentState, state)) {
      var destination = location.pathname;
      if(isIState(state)) {
        destination = ["", state.language, t(state.path + ".titre")].join("/");
      }

      if(destination !== location.pathname) {
        navigate(destination, {state});
      }
    }
  }, [i18n, location.pathname, location.state, navigate, t]);
}
