import DocumentTitre from "../titre/document-titre/DocumentTitre";
import style from "./Grid.module.scss";
import Menu from "../menu/Menu";
import { Outlet } from "react-router-dom";
import PageTitre from "../titre/page-titre/PageTitre";

export default function Grid() {
  return <div className={style["contenu"]}>
    <DocumentTitre />
    <Menu />
    <PageTitre />
    <Outlet />
  </div>;
}
